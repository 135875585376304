<template>
  <v-container>
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-row>
          <v-col cols="12" sm="8">
              <v-text-field label="Username" v-model="email" name="username" prepend-icon="mdi-account" type="text" readonly></v-text-field>
          </v-col>
          <v-col cols="12" sm="8">
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                hint="At least 8 characters"
                counter
                @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="3">
         <v-btn color="primary" @click="triggerResetPassword" :loading="loading"> Submit </v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import router from '../router'

export default {
  name: 'ResetPassword',
  data: () => ({
    valid: true,
    token: '',
    loading: false,
    email: '',
    showPassword: false,
    password: '',
    confirmPassword: '',
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters'
    },
    resetPasswordRequest: { email: '', password: '' }
  }),
  mounted () {
    this.token = this.$route.params.token
    this.triggerGetResetPassInfo()
  },
  computed: mapState({
    user: (state) => state.user.user
  }),
  methods: {
    ...mapActions({ getResetPassInfo: 'session/getResetPassInfo', resetPassword: 'session/resetPassword' }),
    triggerGetResetPassInfo () {
      this.getResetPassInfo(this.token).then(response => {
        this.email = response.data
      }).catch(error => {
        this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response ? error.response.data.message : 'Failed to connect to backend' }, { root: true })
      })
    },
    triggerResetPassword () {
      this.loading = true
      if (this.$refs.form.validate()) {
        this.resetPasswordRequest.username = this.email
        this.resetPasswordRequest.password = this.password
        this.resetPasswordRequest.token = this.token
        this.resetPassword(this.resetPasswordRequest).then(response => {
          this.loading = false
          this.$store.dispatch('alert/displayAlert', { snackbar: false, color: 'success', message: response.data.message }, { root: true })
          router.push('/login')
        }).catch(error => {
          this.loading = false
          this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response.data.message }, { root: true })
        })
      }
    },
    cancel () {
      router.push('/login')
    }
  }
}
</script>
