<template>
  <v-container fluid  class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="5">
        <v-form>
        <v-card class="elevation-12">
          <v-toolbar color="#043775" dark flat>
            <v-toolbar-title>Reset Password</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text class="mt-3">
            <v-form>
              <v-text-field label="Email Addess" v-model="email" name="login" prepend-icon="mdi-account" type="text"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="mt-3">
             <v-btn to="/login">Back</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="#043775" class="white--text" v-on:keyup.enter="login" @click="resetPassword" :loading="loading">Submit</v-btn>
          </v-card-actions>
        </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'ResetPassword',
  data: () => ({
    valid: true,
    loading: false,
    email: ''
  }),
  computed: {
    ...mapState({
      loginError: state => state.session.loginError,
      color: state => state.alert.color,
      timeout: state => state.alert.timeout,
      message: state => state.alert.message
    }),
    snackbar: {
      get: function () {
        return this.$store.state.alert.snackbar
      },
      set: function (newValue) {
        this.$store.state.alert.snackbar = newValue
      }
    },
    containerStyles () {
      return {
        height: `${this.y}px`,
        width: `${this.x}px`
      }
    }
  },
  methods: {
    ...mapActions({
      resetPass: 'session/requestPasswordReset'
    }),
    resetPassword () {
      this.loading = true
      this.resetPass(this.email).then(response => {
        this.loading = false
        this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'success', message: response.data.message }, { root: true })
      }).catch(error => {
        this.loading = false
        this.$store.dispatch('alert/displayAlert', { snackbar: true, color: 'error', message: error.response ? error.response.data.message : 'Failed to connect to backend' }, { root: true })
      })
    }
  }
}
</script>
